import { Locales } from "./locales";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from "./en.json";
import pt from "./pt-BR.json";

export const messages = {
  [Locales.EN]: en,
  [Locales.PT]: pt
};

export const defaultLocale = Locales.PT;

// register i18n module
Vue.use(VueI18n);

const i18n = new VueI18n({
   locale: 'pt-BR', //if you need get the browser language use following "window.navigator.language"
   fallbackLocale: 'en',
   messages: {pt, en},
   silentTranslationWarn: true
})

const translate = (key: string):any => {
  if (!key) {
    return '';
  }
  return i18n.t(key);
};

export { i18n, translate}; //export above method