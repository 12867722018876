import dotenv from "dotenv";

const enviroment: { [key: string]: string } = {
  local: ".env.local",
  prod: ".env",
  test: ".env.test",
}; 

dotenv.config({
  path: enviroment[process.env.NODE_ENV ?? "prod"],
});
export default {
  appMatrix: process.env.VUE_APP_MATRIX_APP ?? '',
  protocol: process.env.VUE_APP_PROXY_PROTOCOL ?? '',
  protocolHttp: process.env.VUE_APP_PROXY_PROTOCOL_HTTP ?? 'http',
  serviceDiscovery: process.env.VUE_APP_MATRIX_SERVICE_DISCOVERY_API_URL ?? '',
  socket: process.env.VUE_APP_MATRIX_SOCKET_API_URL ?? '',
  socketWhatsapp: process.env.VUE_APP_MATRIX_SOCKET_WHATSAPP_API_URL ?? '',
  url: process.env.VUE_APP_MATRIX_BASE_API_URL ?? '',
  port: parseInt(process.env.VUE_APP_MATRIX_BASE_API_PORT ?? ''),
  flowUrl: process.env.VUE_APP_MATRIX_FLOW_URL ?? '',
  googleMaps: process.env.VUE_APP_MATRIX_GOOGLE_MAPS ?? ''
}
