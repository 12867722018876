import { Vue, Component } from "vue-property-decorator";
import axios, { AxiosInstance } from "axios";
import { paths } from "./apiPaths";
import env from "./env";
import { EventBus } from "./eventBus";
import { translate } from "../plugins/i18n/index";

export const apiClient: AxiosInstance = axios.create({
  baseURL: `${env.protocol}${env.serviceDiscovery}`,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": `${env.protocol}${env.serviceDiscovery}`,
    "X-Forwarded-For": `${env.protocol}${env.serviceDiscovery}`,
  },
});

export const setTokenHeader = (token: string) => {
  apiClient.defaults.headers.common["x-access-token"] = token;
};

@Component
export default class Request extends Vue {
  $message;
  $loading;
  paths = paths;

  private async baseRequest(
    method: "get" | "post" | "put" | "delete" | "patch",
    url: string,
    entity?: any,
    params?: any,
    isLoading?: boolean
  )  {
    try {
      isLoading === false ? null : this.$loading?.startLoading();
      const httpResult = await apiClient({ method, url, data: entity, params });
      this.$loading?.endLoading();

      if (httpResult.status >= 200 && httpResult.status <= 299) {
        return httpResult.data;
      } else {
        this.$message?.errorMessage(httpResult.data);
        throw new Error(httpResult.data);
      }
    } catch (error: any) {
      this.$loading?.endLoading();

      if (error?.response?.data.error === "Unauthorized") {
        this.$message?.errorMessage(`${translate("message.unauthorized")}`);
        EventBus.$emit("endLoading", false);
      } else if (error?.response?.data?.errors) {
        this.$message?.errorMessage(error.response.data.errors);
      } else {
        this.$message?.errorMessage(error.response.data.error);
      }
      throw error;
    }
  }

  get(url: string, params?, isLoading?)  {
    return this.baseRequest("get", url, null, params, isLoading);
  }

  put(url: string, entity, isLoading?)  {
    const result = this.baseRequest("put", url, entity, null, isLoading);
    if (entity.isDeleted === true) {
      this.$message?.errorMessage(
        `${translate("message.successfullyDeleted")}`
      );
    }

    return result;
  }

  post(url: string, entity, isLoading?)  {
    return this.baseRequest("post", url, entity, null, isLoading);
  }
  patch(url: string, entity, isLoading?)  {
    return this.baseRequest("patch", url, entity, null, isLoading);
  }
  delete(url: string, entity)  {
    return this.baseRequest("delete", url, entity);
  }

  async remove(url: string, entity)  {
    const result = await this.baseRequest("delete", url, entity.id);
    if (
      result &&
      result.message === "DELETE_LOGIC_SUCCESS_AND_TRANSFER_SUCCESS"
    ) {
      this.$message?.errorMessage(
        translate("message.DELETE_LOGIC_SUCCESS_AND_TRANSFER_SUCCESS")
      );

    } else {

      this.$message?.successMessage(`${translate("message.remove")}`);
    }
    return result;
  }

  async save(
    url: string,
    entity: any,
    isLoading?: any,
    customMessage?: string
  )  {
    if (entity.id) {
      const result = await this.put(url + entity.id, entity, isLoading);
      if (customMessage) {
        this.$message?.successMessage(translate(customMessage));
      }
      else if (result.message) {
        this.$message?.errorMessage(translate(`error.${result.message}`));
      } else {
        this.$message?.successMessage(
          `${translate("message.successfullyChanged")}`
        );
      }

      return result;
    } else {
      const result = await this.post(url, entity, isLoading);

      if (result.message) {
        this.$message?.errorMessage(translate(`error.${result.message}`));
        return;
      }

      if (customMessage) {
        this.$message?.successMessage(translate(customMessage));
      } else {
        this.$message?.successMessage(translate("message.savedSuccessfully"));
      }

      return result;
    }
  }


  async combineGetRequests(urls: string[], params?): Promise<any[]> {
    const getRequests = urls.map(url => {
      return this.baseRequest("get", url, null, params, false);
    });


    const responses = await Promise.all(getRequests);
    return responses;
  }

}