import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layouts/full-layout/Layout.vue";
import Blanklayout from "../layouts/blank-layout/Blanklayout.vue";
import Login from "@/views/auth/Login.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import Report from "@/views/report/Report.vue";
import QueueList from "@/views/queue/QueueList.vue";
import ChannelList from "@/views/channel/ChannelList.vue";
import Chat from "../views/chat/Chat.vue";
import ManagerChat from "@/views/manager/supervisor/lists/ManagerChat.vue";
import Manager from "@/views/manager/supervisor/Manager.vue";
import Users from "@/views/manager/supervisor/UserList.vue";
import BreakList from "@/views/break/BreakList.vue";
import ContactsList from "@/views/contacts/ContactsList.vue";
import Calendarlist from "@/views/calendar/CalendarList.vue";
//import AttendencyRating from "@/views/manager/rating/AttendencyRating.vue";
//import ShootingScheduleList from "@/views/shootingSchedule/ShootingScheduleList.vue";
import Integrations from "@/views/integrations/Integrations.vue";
//import AssetTriggerList from "@/views/assetTrigger/HsmList.vue";
//import ReportHsm from "@/views/reportHsm/ReportHsm.vue";
//import TagList from "@/views/manager/tag/TagList.vue";
//import FeelingList from "@/views/manager/feeling/FeelingList.vue";
import DialogInfo from "../components/commonComponents/DialogInfo.vue";
import PlanList from "@/views/plans/PlanList.vue";
import CompanyList from "@/views/company/CompanyList.vue";
import EventsList from "@/views/events/EventsList.vue";
import AgentList from "@/views/manager/attendancy/AgentList.vue";
import ProfileList from "@/views/profile/ProfileList.vue";
import store from "@/store";
import SatisfactionSurveyList from "@/views/satisfactionSurvey/SatisfactionSurveyList.vue";
import { paths } from "@/services/apiPaths";
import ConfigurationTabs from "@/views/configurations/ConfigurationTabs.vue";
import FinancialList from "@/views/financial/FinancialList.vue";
import ProductList from "@/views/product/ProductList.vue";
import { tokenStore } from "@/store/LocalStorage";
import ReportRequisition from "@/views/report/ReportRequisition.vue";
import ServiceHistoryList from "@/views/historic/ServiceHistoryList.vue";
import ProspectionList from "@/views/Sales/Prospection/ProspectionList.vue";
import PresentationList from "@/views/Sales/Presentation/PresentationList.vue";
import NegotiationList from "@/views/Sales/Negotiation/NegotiationList.vue";
import SaleActionsList from "@/views/Sales/SaleActions/SaleActionsList.vue";

import MaintenanceList from "@/views/maintenance/MaintenanceList.vue";
import Relationship from "../views/Relationship/Relationship.vue";
import Flow from '../views/Flow/Flow.vue'
import ProspectionMarketingList from "@/views/Marketing/Prospection/ProspectionList.vue";
import HsmList from "@/views/Marketing/Hsm/HsmList.vue";
import GroupList from "@/views/Marketing/Groups/GroupList.vue";
import LeadNuturingList from "@/views/Marketing/LeadNuturing/LeadNuturingList.vue";
import CtaList from "@/views/Marketing/CTA/CtaList.vue";
import Request, { setTokenHeader } from "@/services/request";
import AuthFlow from '@/views/auth/AuthFlow.vue'
Vue.use(VueRouter);
const http = new Request();
const routes = [
  {
    path: "/sales",
    component: Layout,
    children: [
      {
        name: "ProspectionList",
        path: "prospection",
        component: ProspectionList,
      },
      {
        name: "PresentationList",
        path: "presentation",
        component: PresentationList,
      },
      {
        name: "NegotiationList",
        path: "negotiation",
        component: NegotiationList,
      },
      {
        name: "SaleActionsList",
        path: "sale-actions",
        component: SaleActionsList,
      },
    ],
  },
  {
    path: "/marketing",
    component: Layout,
    children: [
      {
        name: "ProspectionMarketingList",
        path: "prospection-marketing",
        component: ProspectionMarketingList,
      },
      {
        name: "HsmList",
        path: "hsm",
        component: HsmList,
      },
      {
        name: "GroupList",
        path: "group",
        component: GroupList,
      },
      {
        name: "LeadNuturingList",
        path: "lead-nuturing",
        component: LeadNuturingList,
      },
      {
        name: "CtaList",
        path: "cta",
        component: CtaList,
      },
    ],
  },

  {
    path: "/reports",
    component: Layout,
    children: [
      {
        name: "Dashboard",
        path: "dashboard",
        component: Dashboard,
      },
      {
        name: "Report",
        path: "report",
        component: Report,
      },
      {
        name: "ReportRequisition",
        path: "report-requisition",
        component: ReportRequisition,
      },
    ],
  },
  {
    path: "/",
    redirect: "/reports/dashboard",
    component: Layout,
    children: [
      {
        name: "Chat",
        path: "chat",
        component: Chat,
      },
      {
        name: "ManagerChat",
        path: "manager-chat",
        component: ManagerChat,
      },
    ],
  },
  {
    path: "/register",
    component: Layout,
    children: [
      {
        name: "ChannelList",
        path: "channel",
        component: ChannelList,
      },
      {
        name: "QueueList",
        path: "atendancyqueue",
        component: QueueList,
      },

      //{
      //  name: "AttendencyRating",
      //  path: "attendencyrating",
      //  component: AttendencyRating,
      //},
      {
        name: "Break",
        path: "break",
        component: BreakList,
      },
      {
        name: "AgentList",
        path: "agent-list",
        component: AgentList,
      },
      {
        name: "EventsList",
        path: "events-list",
        component: EventsList,
      },
      {
        name: "CalendarList",
        path: "calendar-list",
        component: Calendarlist,
      },
      //{
      //  name: "TagList",
      //  path: "tag",
      //  component: TagList,
      //},
      //{
      //  name: "FeelingList",
      //  path: "feeling-monitor",
      //  component: FeelingList,
      //},
      {
        name: "ProfileList",
        path: "profile",
        component: ProfileList,
      },
      {
        name: "SatisfactionSurveyList",
        path: "satisfaction-survey-list",
        component: SatisfactionSurveyList,
      },
      {
        name: "ConfigurationTabs",
        path: "configurations",
        component: ConfigurationTabs,
      },
      {
        name: "ProductList",
        path: "product",
        component: ProductList,
      },
    ],
  },

  {
    path: "/auth",
    component: Blanklayout,

    children: [
      {
        name: "FullLogin",
        path: "login",
        component: Login,
      },
      {
        name: "FullForgotPassword",
        path: "forgot-password",
        component: ForgotPassword,
      }, {
        name: "AuthFlow",
        path: "auth-flow",
        component: AuthFlow,
      }
    ],
  },

  {
    path: "/management",
    component: Layout,
    children: [
      {
        name: "Manager",
        path: "manager",
        component: Manager,
      },
      {
        name: "Users",
        path: "users",
        component: Users,
      },
      {
        name: "ServiceHistory",
        path: "servicehistoryList",
        component: ServiceHistoryList,
      },
    ],
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        name: "ContactsList",
        path: "contactslist",
        component: ContactsList,
      },
      {
        name: "Flow",
        path: "flow",
        component: Flow,
      },
      // {
      //   name: "Flow",
      //   path: "flow",
      //   components: {
      //     default: Dashboard,
      //     dialog: DialogInfo,
      //   },
      // },

      {
        name: "Relationship",
        path: "relationship",
        component: Relationship,
      },
      {
        name: "Integrations",
        path: "integrations",
        component: Integrations,
      },
      {
        path: "/nested",
        component: Layout,
        children: [
          {
            name: "nested",
            path: "",
            component: Dashboard,
          },

          {
            name: "nested.dialog",
            path: "dialog",
            components: {
              default: Dashboard,
              dialog: DialogInfo,
            },
          },
        ],
      },
      //{
      //  name: "AssetTriggerList",
      //  path: "asset-trigger",
      //  component: AssetTriggerList,
      //},
      //{
      //  name: "ReportHsm",
      //  path: "report-hsm",
      //  component: ReportHsm,
      //},
    ],
  },
  {
    path: "/administration",
    component: Layout,
    children: [
      {
        name: "CompanyList",
        path: "company-list",
        component: CompanyList,
      },
      {
        name: "Plans",
        path: "plans",
        component: PlanList,
      },
      {
        name: "Financial",
        path: "financial",
        component: FinancialList,
      },
      {
        name: "MaintenanceList",
        path: "maintenance",
        component: MaintenanceList,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

const validateToken = async (token) => {
  try {
    const user = localStorage.getItem("role");
    if (token) {
      await http.post(http.paths.authorizePath, { accessToken: token });
    }

    setTokenHeader(token);
    if (user) {
      return http.get(paths.rolePath + user);
    }
  } catch (error) {
    localStorage.clear();
  }
};

router.beforeResolve((to, from, next) => {
  if (from !== from) {
    return;
  }
  const localToken = tokenStore.get();
  if (to.name === "FullLogin") {
    if (localToken) {
      next("/");
    } else {
      tokenStore.set("");
    }
  }
  next();
});

router.beforeEach((to, from, next) => {
  if (from !== from) {
    next();
  }

  if (!store.getters["auth/isLogged"] && to.name === "FullLogin") {
    next();
    store.dispatch("general/endLoading");
  } else if (
    !store.getters["auth/isLogged"] &&
    to.name === "FullForgotPassword"
  ) {
    next();
    store.dispatch("general/endLoading");
  } else if (
    !store.getters["auth/isLogged"] &&
    to.name === "AuthFlow"
  ) {
    next();
    store.dispatch("general/endLoading");
  } else if (!store.getters["auth/isLogged"]) {
    store.dispatch("general/startLoading");
    const localToken = tokenStore.get();

    if (localToken) {
      validateToken(localToken)
        .then((response) => {
          store.dispatch("auth/setPermissions", response.permissions);
          store.dispatch("auth/setLogin", {
            user: response.user,
            token: localToken,
          });
          store.dispatch("auth/setUser", response.user);
          store.dispatch("general/setUserAgentId", response.user.userAgent.id);
          response?.user?.userAgent?.id ? store.dispatch("general/endLoading") : null
          next("/");
        })
        .catch(() => {
          next("/auth/login");
        });
    } else {
      next("/auth/login");
    }
  } else {
    next();
  }
});

export default router;
