export const paths = {
  //heppy
  queuePath: "/heppy/queue/",
  queueSavePath: "/heppy/queue/save/",
  breakPath: "/heppy/break/",
  categoryPath: "/heppy/category/",
  userAgentPath: "/heppy/user-agent/",
  weekDayPath: "/heppy/week-day/",
  queueUserPath: "/heppy/queue-user/",
  tagPath: "/heppy/tag/",
  userAgentBreak: "/heppy/user-agent-break/",
  userAgentBreakSave: "/heppy/user-agent-break/save",
  pendingAttendencyPath: "/heppy/attendency-queue/pending",
  userAgentBreakReport: "/heppy/user-agent-break-report/",
  feelingMonitorPath: "/heppy/feeling-monitor/",
  periodPath: "/heppy/period/",
  flowPath: "/heppy/flow/",
  attendencyQueueUserAgent: "/heppy/attendency-queue/user-agent-id/",
  attendencyQueue: "/heppy/attendency-queue/queue-id/",
  queueUserAgentStatus: "/heppy/queue-user-agent/user-agent-id/",
  archiveWhatsappPath: "/heppy/messages/arquive/",
  closureAttendencyPath: "/heppy/attendency-queue/custom/",
  queueDeletePath: "/heppy/queue/delete/",
  pathMetrics: "/heppy/metrics/attendencies-agents",
  attendencyByUserIdPath: "/heppy/attendency/custom",
  attendencyPath: "/heppy/attendency/",
  personObservationPath: "/contact/person-observation/",
  queueUserAgent: "/heppy/queue-user-agent/",
  queueUserAgentLogout: "/heppy/queue-user-agent/update-by-user-agent",
  sendMessagePath: "/heppy/messages/send",
  searchMessagesPath: "/heppy/messages/search?",
  userByCompanyPath: "/heppy/user/",
  pathMetricsAttendencies: "/heppy/metrics/attendencies-queue",
  userSavePath: "/heppy/user/save/",
  attendencyTimePath: "/heppy/attendency-time/custom/",
  timePath: "/heppy/attendency-time/",
  actionPath: "/heppy/action/",
  functionalityPath: "/heppy/functionality/",
  functionalityActionPath: "/heppy/functionality-action/",
  contactAddress: "/heppy/contact-address/",
  userPath: "/heppy/user/delete/",
  profilePath: "/heppy/profile/",
  profileSavePath: "/heppy/profile/save/",
  profileUpdatePath: "/heppy/profile/update/",
  profileFunctionalityActionPath: "/heppy/profile-functionality-action/",
  rolePath: "/heppy/role/",
  listAttendencyPath: "/heppy/attendency-queue/list",
  attendencyQueuePath: "/heppy/attendency-queue/",
  attendencyQueueGetAllPath: "/heppy/attendency-queue/getAll",
  configurationPath: "/heppy/configuration/",
  protocolByContactIdPath: "/heppy/attendency/contact?contactId=",
  messageByProtocolPath: "/heppy/messages/custom?protocol=",
  maintenanceSavePath: "/heppy/manutention/save/",
  maintenancePath: "/heppy/manutention/",
  maintenanceUpdatePath: "/heppy/manutention/update/",
  maintenanceAuthorizationPath: "/heppy/manutention-authorization/",
  maintenanceListPath: "/heppy/manutention/list",
  deleteLogicQueuePath:"/heppy/queue/delete-logic/",
  //heppy

  //auth
  forgotPasswordPath: "/auth/forgot-password/temporay-password",
  pathChangePassword: "/auth/change-password/",
  loginPath: "/auth/login/",
  authorizePath: "/auth/authorize",
  authFlowPath: "/auth/oauth/",
  customAuthPath: '/api/auth/callback/custom-oauth?code=',
  //auth

  //invoice
  companySavePath: "/invoice/company/save/",
  companyListPath: "/invoice/company/list/",
  companyProductPlanGetPath: "/invoice/company-product-plan/custom/",
  heppyInvoicePlans: "/invoice/plan/",
  heppyInvoicePlansSave: "/invoice/plan/save/",
  heppyInvoiceProduct: "/invoice/product/",
  financialPath: "/invoice/financial/",
  productPath: "/invoice/product/",
  companyPath: "/invoice/company/",
  companyDeletePath: '/invoice/company-delete/',
  //invoice

  //calendar
  eventParticipantsPath: "/calendar/event-participants",
  calendarPath: "/calendar/calendar/",
  calendarEventPath: "/calendar/event/",
  eventsByParam: "/calendar/event-by-param/",
  eventFullPath: "/calendar/event-full/save",
  calendarRecorrencyPath: "/calendar/recorrency-event/",
  //calendar

  //contact
  contactSearchPath: "/contact/person/getAll",
  personCompletePath: "/contact/create-complete/",
  personPath: "/contact/person/",
  contactEmailPath: "contact/contact-email/",
  contactPhone: "/contact/contact-phone/",
  contactSocialMedia: "/contact/contact-social-media/",
  contactOfficialDocs: "/contact/contact-official-doc/",
  maritalPath: "/contact/marital/",
  militaryPath: "/contact/military/",
  personTypePath: "/contact/person-type/",
  statusPath: "/contact/status/",
  socialMediaTypePath: "/contact/social-media-type/",
  socialMediaPath: "/contact/contact-social-media/",
  cityPath: "/contact/city/",
  contactDocPath: "/contact/contact-official-doc/",
  personContactEmailPath: "/contact/person-contact-email/",
  contactOfficialTypePath: "/contact/official-doc-type/",
  zipCodePath: "/contact/zip-code/",
  personGetComplete: "/contact/person-complete/",
  //contact

  //channel
  channelPath: "/channel-manager/channel/",
  channelDetailPath: "/channel-manager/channel-detail/",
  brokerPath: "/channel-manager/broker/",
  channelCompletePath: "/channel-manager/channel-complete/",
  //channel

  //research
  pathResearch: "/research/research/",
  pathQuestion: "/research/question/",
  pathResearchFull: "/research/create-complete/",
  //research

  //others
  storagePath: "/storage/upload/base64",

  socketPath: "/socket/socket.io/",

  socketWhatsappPath: "/queue/socket.io/",

  sendOthersMessagePath: "/whatsapp/send-message",
  sendToConsumerPath: "/whatsapp/send-media",
  //others
};
